import React from "react";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";

const AboutAreaTwo = () => {
  const parallax = useParallax({
    translateY: [-24, 24],
    rootMargin: 0,
  });

  return (
    <section className="about-area-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="about-img-two text-center">
              <img src="/img/banner/h2_banner_img.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title white-title title-style-two mb-30">
                <span className="sub-title">I'm a Professional</span>
                <h2 className="title">
                  I Can Plan per <br />
                  Your Requirement
                </h2>
              </div>
              <p>
                I have over 22 years of experience in volunteering, teaching, emergency, humanitarian, and development sectors. In development and humanitarian work, I've been involved in projects spanning women empowerment, ECCD, youth education, school feeding, agri-business, IGA, election observation, humanitarian assistance, emergency aid, research, people's organization, primary education, value chain development, climate change, resilient agriculture, livelihood, youth empowerment, skill development, advocacy, decent work, SRHR, child protection, gender-based violence, knowledge management center, integrated water resource management, WaSH, education in emergency, inclusive education, early childhood development, community mobilization, teachers' professional development, and more. My outreach includes rights holders and beneficiaries in urban, slums, villages, islands, and refugee camps.



              </p>
              <Link to="/">
                <span className="arrow">
                  <img src="/img/icon/right_arrow.svg" alt="" />
                </span>

              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="about-shape-wrap">
        <img
          src="/img/images/h2_about_shape01.png"
          alt=""
          className="shape-one"
          ref={parallax.ref}
        />
        <img
          src="/img/images/h2_about_shape02.png"
          alt=""
          className="shape-two"
        />
        <img
          src="/img/images/h2_about_shape03.png"
          alt=""
          className="shape-three"
        />
      </div>
    </section>
  );
};

export default AboutAreaTwo;
